<template>
  <div>
    <top-bar :title="'我的'" :bgc="'#387FF5'" :color="'#fff'"></top-bar>
    <nav-bar :id="3"></nav-bar>
    <div class="banner">
      <img style="width:100%" :src="bannerImg" />
      <div class="title">我的</div>
    </div>
    <div class="user-info">
      <div class="user-top">
        <img :src="myinfo.headImg || defaultHeader" class="header" />
        <div class="center">
          <div class="name" :class="{'isOld40': $isOld}">{{myinfo.userName || '未知'}}
            <div class="login-time" :class="{'isOld40': $isOld}" v-if="myinfo.loginTime">上次登录：{{ myinfo.loginTime }}</div>
          </div>
        </div>
        <img src="@/assets/img/code.png" class="code" @click="getQRCode" />

      </div>

        <div class="user-content">
          <div class="content-item" @click="toUserInfo">
            <img style="width: 20px;height: 20px" src="@/assets/img/user-name.png" alt="">
            <div style="margin-left: 12px;color: #666" :class="{'isOld40': $isOld}">个人信息</div>
            <div style="flex: 1;text-align: right" :class="{'isOld36': $isOld}">{{ mobile }}</div>
            <van-icon style="margin-left: 12px" name="arrow" color="#666" class="bottom-icon"/>
          </div>
          <div class="content-item" @click="toDailyPaper">
            <img style="width: 20px;height: 20px" src="@/assets/img/user-work.png" alt="">
            <div style="margin-left: 12px;color: #666" :class="{'isOld40': $isOld}">工作日报</div>
            <div style="flex: 1;text-align: right;color: #387FF5" :class="{'isOld36': $isOld}">查看更多信息</div>
            <van-icon style="margin-left: 12px" name="arrow" color="#666" class="bottom-icon"/>
          </div>
          <div class="content-item" @click.stop="getRegisterQrCode">
            <img style="width: 20px;height: 20px" src="@/assets/img/user-qrcode.png" alt="">
            <div style="margin-left: 12px;color: #666" :class="{'isOld40': $isOld}">社区二维码</div>
            <div style="flex: 1;text-align: right"></div>
            <van-icon style="margin-left: 12px" name="arrow" color="#666" class="bottom-icon"/>
          </div>
          <div class="content-item" @click.stop="toPreferences">
            <img style="width: 20px;height: 20px" src="@/assets/img/user-hobby.png" alt="">
            <div style="margin-left: 12px;color: #666" :class="{'isOld40': $isOld}">偏好设置</div>
            <div style="flex: 1;text-align: right"></div>
            <van-icon style="margin-left: 12px" name="arrow" color="#666" class="bottom-icon"/>
          </div>
          <div class="content-item" @click.stop="updatePwd">
            <img style="width: 20px;height: 20px" src="@/assets/img/user-password.png" alt="">
            <div style="margin-left: 12px;color: #666" :class="{'isOld40': $isOld}">修改数据验证密码</div>
            <div style="flex: 1;text-align: right"></div>
            <van-icon style="margin-left: 12px" name="arrow" color="#666" class="bottom-icon"/>
          </div>
          <div class="content-item" @click="$router.push('./mobile-change')">
            <img style="width: 20px;height: 20px" src="@/assets/img/user-mobile.png" alt="">
            <div style="margin-left: 12px;color: #666" :class="{'isOld40': $isOld}">更换手机号</div>
            <div style="flex: 1;text-align: right"></div>
            <van-icon style="margin-left: 12px" name="arrow" color="#666" class="bottom-icon"/>
          </div>
          <div class="content-item">
            <img style="width: 20px;height: 20px" src="@/assets/img/user-isOld.png" alt="">
            <div style="margin-left: 12px;color: #666" :class="{'isOld40': $isOld}">长辈模式</div>
            <div style="flex: 1;text-align: right;margin-right: 12px;" :class="{'isOld36': $isOld}">开启后字体更大、按钮更大</div>
            <!-- <van-switch v-model="isOld" size="24" @click="isOldChangeShow = true" /> -->
            <img v-if="!isOld" style="width: 73px;height: 73px;" @click="isOldChangeShow = true" :src="require('@/assets/img/close.png')" alt="">
            <img v-if="isOld" style="width: 73px;height: 73px;margin-right: -23px;" @click="isOldChangeShow = true" :src="require('@/assets/img/isOld-open.png')" alt="">
          </div>
          <!-- <div class="content-item">
            <img style="width: 20px;height: 20px" src="@/assets/img/user-name.png" />
            <div style="margin-left: 12px;color: #666">姓名</div>
            <div style="flex: 1;text-align: right">{{ myinfo.userName }}</div>
          </div>
          <div class="content-item">
            <img style="width: 20px;height: 20px" src="@/assets/img/user-mobile.png" />
            <div style="margin-left: 12px;color: #666">手机号码</div>
            <div style="flex: 1; text-align: right; color: #387FF5" @click="$router.push('./mobile-change')">{{ myinfo.mobile }}</div>
          </div>
          <div class="content-item">
            <img style="width: 20px;height: 20px" src="@/assets/img/user-org.png" />
            <div style="margin-left: 12px;color: #666">所属机构</div>
            <div style="flex: 1;text-align: right">{{ myinfo.orgName }}</div>
          </div>
          <div class="content-item">
            <img style="width: 20px;height: 20px" src="@/assets/img/user-post.png" />
            <div style="margin-left: 12px;color: #666">职务</div>
            <div style="flex: 1;text-align: right">{{ myinfo.userPostName }}</div>
          </div>
          <div class="content-item">
            <img style="width: 20px;height: 20px" src="@/assets/img/user-type.png" />
            <div style="margin-left: 12px;color: #666">人员类型</div>
            <div style="flex: 1;text-align: right">{{ myinfo.typeName }}</div>
          </div>
          <div class="content-item">
            <img style="width: 20px;height: 20px" src="@/assets/img/user-charges.png" />
            <div style="margin-left: 12px;color: #666">负责业务</div>
            <div style="flex: 1;text-align: right">{{ myinfo.chargesName }}</div>
          </div>
          <div class="content-item" style="border: none">
            <img style="width: 20px;height: 20px" src="@/assets/img/user-password.png" />
            <div style="margin-left: 12px;color: #666">密码</div>
            <div style="flex: 1;text-align: right; color:#387FF5" @click="updatePwd">修改密码</div>
          </div> -->
        </div>
        <div class="unlink-btn" @click="quit">解绑账号</div>

    </div>
    <pwd-change ref="pwdChange" v-if="setPasswordVisible"></pwd-change>
    <!-- 自主登记二维码 -->
    <van-dialog
      v-model="qrcodeShow"
      class="qrcode"
      :show-confirm-button="false">
        <img class="code-img" :src="codeImg" alt="">
        <div class="text">{{streetName}}-{{communityName}}</div>
        <div class="text">自主登记二维码</div>
        <van-icon class="cross" name="cross" size="24" @click="qrcodeShow = false" />
    </van-dialog>
    <!-- 是否切换 -->
    <van-dialog
      class="isOld-dialog"
      v-model="isOldChangeShow"
      show-cancel-button
      show-confirm-button
      confirm-button-text="确定"
      confirm-button-color="#387FF5"
      @confirm="isOldConfirm"
      @cancel="isOldCancel"
    >
      <div>是否切换到{{isOld ? '正常版' : '长辈版'}}？</div>
    </van-dialog>
  </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'
import navBar from '@/components/navBar/navBar'

import { getImageStream } from '@/utils/index'
import { desensitization } from '@/utils/utils'
import pwdChange from '@/components/pwdChange/pwdChange'
import Vue from 'vue'
export default {
  data() {
    return {
      bannerImg: getImageStream('files/wx/images/content/my-banner.png',process.env.VUE_APP_BASE_BACK_URL),
      defaultHeader: getImageStream('files/wx/images/content/header-default.png',process.env.VUE_APP_BASE_BACK_URL),
      myinfo: {
        headImg: '',
        userName: '',
        mobile: '',
        orgName: '',
        userPostName: '',
        typeName: '',
        chargesName: '',
        loginTime: ''
      },
      mobile: '',
      setPasswordVisible: false,
      isOld: false,
      qrcodeShow: false,
      codeImg: '',
      streetName: '',
      communityName: '',
      isOldChangeShow: false,
    }
  },
  components: {
    topBar,
    navBar,
    pwdChange
  },
  created() {
    // document.querySelector('html').style.backgroundColor = '#387FF5'
    // document.querySelector('body').style.backgroundColor = '#387FF5'
    this.getjmgginfo()
    this.isOld = this.$isOld ? true : false
    console.log(this.$isOld,111);
  },
  beforeDestroy() {
    // document.querySelector('html').style.backgroundColor = ''
    // document.querySelector('body').style.backgroundColor = ''
  },
  methods: {
    updatePwd () {
      this.setPasswordVisible = true
      this.$nextTick(() => {
        this.$refs.pwdChange.init(true)
      })
    },
    toUserInfo() {
      this.$router.push('/personal-info')
    },
    toDailyPaper() {
      this.$router.push('/daily-paper')
    },
    getRegisterQrCode() {
      this.qrcodeShow = true
      this.$http({
        url: this.$http.adornUrl('/wxapp/home/getRegisterQrCode'),
        method: 'GET',
        params: this.$http.adornParams({
          path : process.env.VUE_APP_BASE_CUSTAPP_URL
        })
      }).then(({data}) => {
        if(data && data.code === 0) {
          this.codeImg = getImageStream(data.qrCode)
        }
      })
    },
    toPreferences() {
      this.$router.push('/preferences')
    },
    getinfo() {
      this.$router.push('/my-info')
    },
    getQRCode() {
      this.$router.push('/my-qrcode')
    },
    quit() {
      let from = this.$globalData.from
      let url = '/wxapp/dd/unlink'
      if (from && from === 'shb'){
        url = '/wxapp/shb/unlink'
      }
      this.$dialog.confirm({
        title: '解绑账号',
        message: '该操作将解绑账号,确定吗?',
      }).then(() => {
        this.$http({
          url: process.env.VUE_APP_BASE_URL + url,
          method: 'post',
          params: this.$http.adornParams({
            id: this.$globalData.topUserId
          },false)
        }).then(({data}) => {
          if (data.code == 0) {
            this.$globalData.token = ''
            this.$globalData.topToken = ''
            this.$globalData.backToken = ''
            this.$globalData.areaToken = ''
            this.$router.replace('/white')
          } else {
            this.$toast.fail(data.msg);
          }
        })
      })
    },
    getjmgginfo() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/user/my/info'),
        method: 'post',
        params: this.$http.adornParams({
          orgId: this.$globalData.userInfo.orgId,
          id: this.$globalData.userInfo.userId
        }, false)
      }).then(({ data }) => {
        if (data.code == 0) {
          let userManager = data.userManager
          this.myinfo.headImg = userManager.headImg ? getImageStream(userManager.headImg) : this.defaultHeader
          this.myinfo.userName = userManager.name || app.globalData.userInfo.communityName + '社工'
          this.myinfo.mobile = desensitization(userManager.mobile) || ''
          this.mobile = userManager.manageMobile || ''
          this.myinfo.orgName = userManager.streetName + '-' + userManager.communityName
          this.myinfo.userPostName = userManager.postName || '无'
          this.myinfo.typeName = userManager.typeName || ''
          this.myinfo.chargesName = userManager.chargesName || '无'
          this.myinfo.loginTime = userManager.loginTime
          this.streetName = userManager.streetName
          this.communityName = userManager.communityName
        } else {
          this.$toast.fail(data.msg);
        }
      })
    },
    changeCommunity() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/user/res/list/community'),
        method: 'post',
        params: this.$http.adornParams({
          idNumber: '',
          userId: this.$globalData.userInfo.userId
        }, false)
      }).then(({ data }) => {
        if (data.code == 0) {
          if (data.list[1]) {
            Vue.prototype.$orgList = data.list
            this.$router.push('/community-change')
          } else {
            this.$toast.fail('没有获取到你在其他社区的信息');
          }
        } else {
          this.$toast.fail(data.msg);
        }
      })
    },
    // isOldChange() {
    //   this.isOldChangeShow = true
    // },
    isOldConfirm() {
      let version = this.isOld ? 0: 1
      this.$http({
        url: this.$http.adornUrl('/wxapp/user/setOldVersion'),
        method: 'GET',
        params: this.$http.adornParams({
          userId: this.$globalData.userInfo.userId,
          version,
        })
      }).then(({data}) => {
        if(data && data.code === 0) {
          this.isOld = version ? true : false
          Vue.prototype.$isOld = version
          this.isOldChangeShow = false
        }
      })
    },
    isOldCancel() {
      this.isOldChangeShow = false
    },
  },
}
</script>

<style lang="scss" scoped>
.banner {
  position: relative;
  height: 306px;
}

.title {
  font-size: 28px;
  position: absolute;
  top: 48px;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
}

.user-info {
  padding-top: 24px;
  padding-bottom: 200px;
  min-height: calc(100vh - 296px);
  position: absolute;
  top: 366px;
  border-radius: 20px 20px 0px 0px;
  width: 100%;
  background: #fff;
}

.user-top {
  display: flex;
  align-items: center;
  margin: 0 auto;
  width: 690px;
  height: 190px;
  box-shadow: 0px 2px 12px 0px rgba(102, 102, 102, 0.1);
  border-radius: 10px;
  padding: 40px 34px 40px 24px;
  box-sizing: border-box;
}

.header {
  width: 100px;
  height: 100px;
}

.user-top .center {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  margin-left: 24px;
  flex: 1;
  padding: 5px 0;
  box-sizing: border-box;
}

.user-top .name {
  font-size: 32px;
  line-height: 54px;
  color: #333;
}

.user-top .login-time {
  font-size: 24px;
  line-height: 40px;
  color: #999;
}

.code {
  width: 36px;
  height: 36px;
}

.user-content {
  margin: 24px 30px 0;
  width: 690px;
}

.content-item {
  display: flex;
  align-items: center;
  height: 88px;
  border-bottom: 1px solid #EDEDED;
  font-size: 28px;
  margin-bottom: 14px;
}

.unlink-btn {
  font-size: 28px;
  margin: 40px 30px 0;
  width: 690px;
  height: 88px;
  line-height: 88px;
  opacity: 0.6;
  background: #387FF5;
  border-radius: 10px;
  text-align: center;
  color: #fff;
}
.qrcode {
  ::v-deep .van-dialog__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .code-img {
      margin-top: 25px;
    }
    .text {
      margin-bottom: 20px;
    }
  }
  .cross {
    position: absolute;
    top: 20px;
    right: 40px;
  }
}
.isOld-dialog {
  ::v-deep .van-dialog__content {
    margin: 50px 0;
    text-align: center;
  }
}
</style>
